import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-victorville-california.png'
import image0 from "../../images/cities/scale-model-of-california-route-66-museum-in-victorville-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Victorville'
            state='California'
            image={image}
            lat='34.5362184'
            lon='-117.2927641'
            attractions={ [{"name": "California Route 66 Museum", "vicinity": "16825 D St, Victorville", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 34.5371351, "lng": -117.2943851}] }
            attractionImages={ {"California Route 66 Museum":image0,} }
       />);
  }
}